// External dependencies
import { Div, Icon } from 'atomize';
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app//redux/store';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { setLocalStorage } from '@shared/utils/helpers';
import { getDateTime } from '@shared/utils/renderDate';
import { getUserRole, UserRoles } from '../../../shared/utils/roles';
import { getTableColumnsForListCourses } from './helpers';
import ManagerControlBarCourses from './ManagerControlBarCourses';
import Header from '@shared/ui/admin/header/Header';

export default function ListCourses() {
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { courses, loading, searchString, total } = useSelector(
    (state: RootState) => state.listCourses,
  );
  const { language } = useI18next();

  function getImage(url: string) {
    return (
      <Div d="inline-block" p="10px">
        <Div d="flex" justify="center" align="center">
          {url ? (
            <img src={url} width="60px" />
          ) : (
            <Icon name="photo" size="60px" />
          )}
        </Div>
      </Div>
    );
  }

  function alignNumberInCenter(value: any) {
    return <Div textAlign="center">{`${value} `}</Div>;
  }

  function getUserAndNavigateToCourseDetail(id: string, __typeName: string) {
    setLocalStorage('courseId', id);
    setLocalStorage('courseTypename', __typeName);

    navigate('course-details');
  }

  try {
    return (
      <Div m="0 auto" className="container">
        <Header />
        <Div p={{ t: '80px' }}>
          <Table
            items={courses?.map((course) => ({
              id: course?.id,
              ageRestrictions: alignNumberInCenter(course?.ageRestrictions),
              courseItemsCount: alignNumberInCenter(course?.courseItemsCount),
              coursePrice: `${course?.coursePrice?.value} ${course?.coursePrice?.currency}`,
              description: course?.description?.[language],
              image: getImage(course?.image?.[language]),
              purchaseStatus: course?.purchaseStatus,
              likesCount: alignNumberInCenter(course?.likesCount),
              postDate: getDateTime(course?.postDate),
              status: course?.status,
              title: course?.title?.[language],
              __typeName: course?.typename,
            }))}
            onClickItem={getUserAndNavigateToCourseDetail}
            columns={getTableColumnsForListCourses()}
            currentPage={3}
            emptyMessage="noCourses"
            controlBar={getControlBar()}
            loading={loading}
            onPageChange={() => {}}
            stickyCol={2}
            searchString={searchString}
            selectionType={RowSelectionType.NUMBER}
            topBar={''}
            totalItemsCount={total}
          />
        </Div>
      </Div>
    );
  } catch (error) {
    return (
      <Div className="loading">
        <Div className="loading__icon"></Div>
      </Div>
    );
  }

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER):
        return <ManagerControlBarCourses />;

      case userRole.includes(UserRoles.MEDUCATION_MANAGER):
        return <ManagerControlBarCourses />;

      case null: {
        throw new Error('client has not set up yet');
      }

      default:
        throw new Error('Permission denied');
    }
  }
}
