// External dependencies
import React from 'react';
import { Trans } from 'react-i18next';

export function getTableColumnsForListCourses() {
  return [
    {
      field: 'id',
      title: <Trans>ID</Trans>,
    },
    {
      field: 'image',
      title: <Trans>image</Trans>,
    },
    {
      field: 'title',
      title: <Trans>title</Trans>,
    },
    {
      field: 'status',
      title: <Trans>status</Trans>,
    },
    {
      field: 'description',
      title: <Trans>description</Trans>,
    },
    {
      field: 'coursePrice',
      title: <Trans>price</Trans>,
    },
    {
      field: 'postDate',
      title: <Trans>postDate</Trans>,
    },
    {
      field: 'likesCount',
      title: <Trans>likesCount</Trans>,
    },
    {
      field: 'purchaseStatus',
      title: <Trans>purchaseStatus</Trans>,
    },

    {
      field: 'courseItemsCount',
      title: <Trans>courseItemsCount</Trans>,
    },
    {
      field: 'ageRestrictions',
      title: <Trans>ageRestrictions</Trans>,
    },
  ];
}
