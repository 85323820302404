// External dependencies
import { Div, Text } from 'atomize';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app//redux/store';
import Button from '@shared/ui/button';
import CustomDropdown from '@shared/ui/custom-dropdown';
import Search from '@shared/ui/search/Search';
import { CourseStatus } from '../types';
import {
  listCourses,
  listCoursesChangeCurrentFilter,
  listCoursesChangeSearchString,
} from './redux/actions';

export default function ManagerControlBarCourses() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, filter, searchString, total } = useSelector(
    (state: RootState) => state.listCourses,
  );
  const searchPlaceholderText = t('placeholderUserActionText');

  useEffect(() => {
    dispatch(listCourses(searchString, filter));
  }, [filter, searchString]);

  function onSearch(e) {
    dispatch(listCoursesChangeSearchString(e.target.value));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function handleSelectStatusFilter(value) {
    dispatch(listCoursesChangeCurrentFilter(value));
  }

  function navigateToCreateCoursePage() {
    navigate('create-course');
  }

  return (
    <>
      <Text textSize="subtitle" textWeight="700" m={{ b: '20px' }}>
        {searchString ? <Trans>resultOfSearch</Trans> : <Trans>courses</Trans>}{' '}
        ({loading ? '...' : total})
      </Text>
      <Div
        m={{ b: '20px' }}
        d="flex"
        align="center"
        flexWrap="wrap"
        justify="space-between"
      >
        <Div d="flex" align="center" style={{ gap: '30px' }} flexWrap="wrap">
          <Div>
            <Search
              onChange={onSearch}
              placeholder={searchPlaceholderText}
              onClear={resetSearchField}
              value={searchString}
            />
          </Div>
          <Div style={{ zIndex: 99 }} w="200px">
            <CustomDropdown
              width="100%"
              items={[CourseStatus.ENABLED, CourseStatus.DISABLED]}
              name={filter?.status}
              onSelectAction={handleSelectStatusFilter}
            />
          </Div>
        </Div>
        <Div>
          <Button typeColor="primary" onClick={navigateToCreateCoursePage}>
            <Trans>createCourse</Trans>
          </Button>
        </Div>
      </Div>
    </>
  );
}
